* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  font-family: "Roboto", sans-serif !important;
  background-color: #fdf8f4 !important;
}

/* Header */
#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 120px;
  background: transparent;
  z-index: 999;
  position: sticky;
  top: 0;
}

p {
  color: #737372;
  font-size: 14px;
  font-weight: 500;
}

#header a {
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  color: #fbbf24;
}

#navbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

#navbar li {
  list-style: none;
  padding: 0 20px;
  position: relative;
}

#navbar li a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  transition: 0.3s ease;
}

#navbar li a:hover,
#navbar li a.active {
  color: #fbbf24;
}

/* #navbar li a.active::after,
#navbar li a:hover::after {
  content: '';
  width: 30%;
  height: 2px;
  background: #FBBF24;
  position: absolute;
  bottom: -4px;
  left: 20px;
} */

.time {
  background-color: rgba(255, 101, 1, 0.12);
  border-radius: 8px;
  color: #ff6501;
  display: inline-block;
  padding-left: 0.25em;
  padding-right: 0.25em;
}

.add-shadow {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
  background: #fff !important;
}

#mobile {
  display: none;
}

#close {
  display: none;
}

/* Hero Section */

#hero {
  /* background-image: url('./assets/images/hero.png'); */
  width: 100%;
  height: fit-content;
}

.py-8 {
  padding-top: 100px;
}

#hero h2,
h3 {
  font-size: 40px !important;
  font-weight: 600 !important;
}

#hero p {
  font-size: 18px;
  font-weight: 500;
  color: #737372;
}

button,
.btn-link {
  border: none;
  background-color: #d97706;
  color: #ffffff;
  outline: none;
  padding: 12px 30px;
  border-radius: 10px !important;
}

.btn-link {
  text-decoration: none;
}

.btn-link:hover {
  color: #ffffff;
  filter: brightness(0.9);
}

button:hover {
  filter: brightness(0.9);
}

.floating-img {
  transform: translateY(-3%);
  animation: floater 4.5s infinite;
  transition: ease 0.5s;
}

@keyframes floater {
  0% {
    transform: translateY(-3%);
    transition: ease 0.5s;
  }

  50% {
    transform: translateY(3%);
    transition: ease 0.5s;
  }
}

/* senders */

#senders {
  padding: 100px 0;
}

#senders .send {
  padding: 0px 0px 0px 80px;
}

p.senders {
  font-size: 24px;
  font-weight: 400;
  color: #949494;
}

#senders h3 {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 20px;
}

#senders .content,
p.content {
  font-size: 20px;
  font-weight: 500;
  color: #737372;
  margin-bottom: 20px;
}

li {
  font-size: 20px;
  font-weight: 500;
  color: #737372;
}

#senders a {
  text-decoration: none;
  color: #d97706;
  font-size: 20px;
  font-weight: 500;
}

.apps {
  margin: 40px 0;
}

/* Partners */

#partners {
  padding: 50px 0;
}

#partners h3 {
  font-size: 36px;
  font-weight: 800;
}

.extra-cash {
  color: #059669;
}

#partners p {
  font-size: 20px;
  font-weight: 500;
  color: #737372;
  margin-bottom: 20px;
}

#partners a {
  text-decoration: none;
  color: #d97706;
  font-size: 20px;
  font-weight: 500;
}

.trans {
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #000000;
  text-align: center;
}

/* Centers */

#centers {
  background-color: #ecfdf5;
  padding: 100px 0;
}

#centers p {
  font-size: 20px;
  font-weight: 500;
  color: #737372;
  margin-bottom: 20px;
}

#centers h3 {
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 20px;
}

#centers a {
  text-decoration: none;
  color: #d97706;
  font-size: 20px;
  font-weight: 500;
}

/*  */

.transport {
  background-color: #fff;
  box-shadow: 0 4px 29px 0 rgba(0, 0, 0, 0.05);
  color: #ff6501;
  border-radius: 10px;
  font-size: 30px;
  padding: 15px 20px;
}

/* Footer */

.footer-header {
  /* opacity: 0.5; */
  color: #fbbf24;
  font-size: 16px;
  line-height: 130%;
  font-weight: 600;
  margin-bottom: 25px;
}
.footer-link a {
  display: block;
  color: #0c1d37;
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
}

.footer-link a:hover {
  color: #ff6501;
  transform: translateX(5px);
}

.card {
  min-height: 150px;
  background-color: #fbf2eb !important;
}

.carousel-indicators,
.carousel-next-icon {
  color: #0c1d37 !important;
}

.pac-container {
  z-index: 10000 !important;
}

@media (max-width: 799px) {
  #navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    right: -300px;
    height: 100vh;
    width: 300px;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 80px 0 0 10px;
    transition: 0.3s;
    background-color: #fff;
  }

  #navbar.active {
    right: 0px;
  }

  #navbar li {
    margin-bottom: 25px;
  }

  #mobile {
    display: flex;
    align-items: center;
  }

  #mobile i {
    color: #1a1a1a;
    font-size: 24px;
    padding-left: 20px;
  }

  #close {
    display: initial;
    position: absolute;
    top: 30px;
    left: 30px;
    color: #222;
    font-size: 24px;
  }

  #hero h2 {
    font-size: 26px;
  }

  #hero p {
    font-size: 14px;
    font-weight: 500;
  }

  button {
    padding: 10px 26px;
  }

  #hero {
    height: fit-content;
  }

  #senders {
    padding: 40px 0;
  }

  #senders h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  #senders .content {
    font-size: 14px;
    margin-bottom: 0;
  }

  #senders a {
    font-size: 10px;
    font-weight: 600;
  }
  #centers a {
    font-size: 14px;
  }

  p.content {
    font-size: 14px;
  }

  #footer h5 {
    font-size: 14px;
  }
  #footer .footer-link a {
    font-size: 12px;
  }
  .apps {
    margin: 20px 0;
  }
  .app-img {
    width: 140px;
  }

  p.senders {
    font-size: 14px;
  }

  #partners {
    padding: 40px 0;
  }

  #partners p {
    font-size: 14px;
  }

  #partners h3,
  h3 {
    font-size: 21px;
  }

  #partners a {
    font-size: 14px;
  }

  #centers p {
    font-size: 14px;
  }

  #centers h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }
}

@media (max-width: 477px) {
  .py-5 {
    padding-top: 0.25rem !important;
    padding-bottom: 3rem !important;
  }
  #header {
    padding: 10px 30px;
  }

  .reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .col {
    flex: 1 4 45% !important;
  }
}

/* Accordion */
.accordion-button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
  font-size: 1rem;
  color: #616368;
  text-align: left;
  /* background-color: #F5F7FA; */
  /* border: 1px solid rgba(255, 255, 255, 0.125); */
  border-radius: 0;
  overflow-anchor: none;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, border-radius 0.15s ease,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.accordion-button.collapsed {
  border-bottom-width: 0;
}

.accordion-button:not(.collapsed) {
  color: #1f1534;
  background-color: #fbf2eb;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.accordion-button::after {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 1.8rem;
  height: 1.8rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1.8rem;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #fff;
  outline: 0;
  -webkit-box-shadow: #e2eaed;
  box-shadow: #e2eaed;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion-collapse {
  border: solid rgba(255, 255, 255, 0.125);
  border-width: 0 1px;
}

.accordion-body {
  padding: 1.5rem 2.6rem;
}

.accordion-flush .accordion-button {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item:first-of-type .accordion-button {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
